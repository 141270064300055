<template>
  <v-text-field
    type="text"
    v-model="val"
    autocomplete="off"
    dense
    hide-details

    :rules="rules"
    :label="i18n(label)"
    :outlined="outlined"
    :autofocus="autofocus"
  />
</template>

<script>
const onlyString = (str) => String(str || '').trim()

export default {
  props: {
    value: {},
    rules: {},
    label: {},
    outlined: { default: false },
    clearable: { default: true },
    autofocus: { default: false }
  },

  data () {
    return {
      val: onlyString(this.value),
      timeout: null
    }
  },

  methods: {
    clearTimeout () {
      clearTimeout(this.timeout)
      this.timeout = null
    }
  },

  watch: {
    val () {
      this.clearTimeout()

      this.timeout = setTimeout(() => {
        const val = onlyString(this.val)
        const changed = val !== onlyString(this.value)
        if (changed) this.$emit('input', val)
      }, 275)
    },

    value () {
      const value = onlyString(this.value)
      const changed = value !== onlyString(this.val)
      if (changed) this.val = value
    }
  }
}
</script>
